@use "@angular/material" as mat;
// @import "@angular/material/theming";
@import "./se_variables";

//Define custom fonts
@font-face {
  font-family: SiemensSans-Bold;
  src: url(../fonts/SiemensSans_Prof_Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: SiemensSans-BoldItalic;
  src: url(../fonts/SiemensSans_Prof_BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SiemensSans-Italic;
  src: url(../fonts/SiemensSans_Prof_Italic.ttf);
  font-style: italic;
}
@font-face {
  font-family: SiemensSans;
  src: url(../fonts/SiemensSans_Prof_Roman.ttf);
}

// create custom typography
$custom-typography: mat.m2-define-typography-config(
  $font-family: "SiemensSans, Roboto, sans-serif",
);

// merge custom typography with material
@include mat.all-component-typographies($custom-typography);

// ==============================================

// set global style of components
@import "./components/se_nav.scss";

.de-dialog-container {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}
// snack bar color classes

.mdc-snackbar__label {
  color: grey !important;
}
.alert-success.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  color: #3c763d !important;
  background-color: #dff0d8 !important;
  border-color: #d6e9c6 !important;
}
.alert-info.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  color: #31708f !important;
  background-color: #d9edf7 !important;
  border-color: #bce8f1 !important;
}
.alert-warning.mat-mdc-snack-bar-container .mdc-snackbar__surface{
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
  border-color: #faebcc !important;
}
.alert-danger.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  color: #a94442 !important;
  background-color: #f2dede !important;
  border-color: #ebccd1 ;
}

.error {
  color: $se-color-error;
}
